import { useEffect, useState } from "react";
import options from "../options.json";



function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return 0;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

// FormatBytes Thanks to https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript

export default function ProjectCard(props) {
  const [languages, setLanguages] = useState({});
  const [contributors, setContributors] = useState([]);
  function getLanguages() {
    fetch(props.project.languages_url, options)
      .then((res) => res.json())
      .then((data) => {
        setLanguages(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getContributors() {
    fetch(props.project.contributors_url,options)
      .then((res) => res.json())
      .then((data) => {
        setContributors(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getLanguages();
    getContributors(); // eslint-disable-next-line
  }, []);

  return (
    <li className="project">
      <div className="project__wrapper project__wrapper--first" >
        <div className="project__titleWrapper">
         <img src="images/icons/repo.png" className="project__image" alt="Repo Book" />
         <h3 className="">{props.project.name || "N/A"}</h3>
        </div>
        <p className="project__description">{props.project.description || "N/A"}</p>
        <a className="project__button" target="_blank" rel="noreferrer" href={props.project.html_url || "N/A"}>{"View Repo"}</a>
      </div>
      <div className="project__wrapper project__wrapper--second">
        <p>Created On: {props.project.created_at.substring(0,10) || "N/A"}</p>
        <p>Updated On: {props.project.updated_at.substring(0,10) || "N/A"}</p>
        <p>Languages</p>
        <ul className="languagesList">
          {Object.entries(languages).map((e , index) => (
            <li className="languagesList__item" key={index}>{`${e[0]}: ${formatBytes(e[1])}`}</li>


          ))}
        </ul>
        <p>Contributors : </p>
        <ul className="contributorsList">
          {contributors.map((contributor) =>
            contributor.login === props.project.owner.login || contributor.type === "Bot" ? null : (
              <li key={contributor.id} className="contributorsList__item">
                <p className="hidden">{contributor.login}</p>
                <a target="_blank" rel="noreferrer" href={contributor.html_url} >
                  <img src={contributor.avatar_url}  alt="logo"/>
                  <p style={{display:"none"}}>{contributor.login}</p>
                </a>
              </li>

            )
          )}
        </ul>
      </div>
    </li>
  );
}
