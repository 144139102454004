import { useEffect, useState } from "react";

import options from "./options.json";

export default function Introduction() {
  const [data, setData] = useState("");
  useEffect(() => {
    fetch("https://api.github.com/users/CameronTwiggs", options)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <section className="aboutme sect">
      <div className="aboutme--half">
        <h2 className="aboutme__title title">$A little about me... </h2>
        <p className="aboutme__text">{data.bio}</p>
        <a className="btn" href="https://www.linkedin.com/in/camerontwiggs/">
          LinkedIn!{" "}
        </a>
      </div>
      <img src={data.avatar_url} alt="logo" className="aboutme__logo" />
    </section>
  );
}
