import { useEffect, useState } from "react";
import ProjectCard from "./components/ProjectCard";
import options from "./options.json";



export default function Projects() {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch("https://api.github.com/users/CameronTwiggs/repos", options)
      .then((res) => res.json())
      .then((data) => {
        let projects = [];
        data.forEach((project) => {
          if (project.topics.includes("portfolio-project")) {
            projects.push(project);
          }
        });
        setData(projects);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <section className="projects sect">
      <h2 className="project__title title">$Previous projects... </h2>
      <ul className="projectList">
        {data.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </ul>
    </section>
  );
}
