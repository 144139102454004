import "./styles/styles.css";
import Introduction from "./Introduction";
import Projects from "./Projects";

function App() {
  return (
    <>
      <header>
        <h1 className="header__text title">$My name is Cameron Twiggs... </h1>
      </header>
      <main className="scroll">
        <Introduction />
        <Projects />
        <section className="hire sect">
          <h2 className="hire__title title">Would you like to reach out..? </h2>
          <p className="hire__text">
            Think that I could just the person you are looking for as a hire?
            Please contact me!
          </p>
          <a className="hire__button btn" href="/#">
            Contact Me
          </a>
        </section>
      </main>
      <footer>
        <ul>
          <li>
            <a href={"https://www.facebook.com/"}>
              <img src="images/icons/facebook.svg" alt="Facebook" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/">
              <img src="images/icons/twitter.svg" alt="Twitter" />
            </a>
          </li>
          <li>
            <a href="https://github.com/CameronTwiggs">
              <img src="images/icons/github.svg" alt="GitHub" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/">
              <img src="images/icons/instagram.svg" alt="Instagram" />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/camerontwiggs/">
              <img src="images/icons/linkedin.svg" alt="LinkedIn" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default App;
